import React from "react"
import { Container, Grid } from '@material-ui/core'

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from '../components/onecol'
import Locations from '../components/locations'
import Contact from '../components/form/contact'
import Map from '../components/map/index'

import Banner from '../components/banner'
import ContactBanner from '../images/contact-us-banner.jpg'

const ContactUs = () => (
    <Layout>

    <SEO 
        title="Contact Us" description="We’re here for you! Connect today & we’ll be sure to look after you with quality customer care."
    />

        <Banner
          title="Contact Us"
          img={ContactBanner}
        />    

    <Onecol
        title="We are always happy to hear from you"
        description="We are here to provide you with more information, answer any questions you may have and create an effective solution for your business needs."
    />

    <Container className="pb-40">
        <Grid items container>
            <Grid md={6} sm={12} className="form">
                <Contact />
            </Grid>
            <Grid md={6} sm={12} className="contact-locations-container">
                <Locations />
            </Grid>
        </Grid>
    </Container>

    <Map />

    </Layout>
)

export default ContactUs